p {
  text-align: left;
  margin-block: 0px;
}

.footer-container {
  /*position: fixed;
  bottom: 0;*/
  margin: 20px;
}
