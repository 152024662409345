.wgcnaTable {
    width: 90%;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    border-collapse: collapse;
}

 td, th {
    border: 2px solid #aaaaaa;
    padding: 6px;
}
