.home-container {
    text-align: justify;
    margin: 20px;

}

.title {
    text-align: center;
    margin: 20px;
    font-weight: bold;
}

.image-container {
    margin: 40px;
}

img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 80%;
    height: auto;
  }