.logo {
    color: white;
}

.navlinks {
    margin: 20px 20px 20px 40px;
}

.link {
    text-decoration: none;
    margin: 20px 20px 20px 20px;
    color: white;//rgb(18, 255, 180);
}

.active {
    color: black;
    //background-color: cadetblue;
    //padding: 0px 0px 3px 0px;
    //border-radius: 4px;
  }

.link:hover {
    color: black;
}
