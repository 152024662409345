$correlationChartMargin: 180px;
$chartAspectRatio: 16/9;
$chartAspectRatioWide: 5/4;
$defaultChartMargin: 40px;
$tinyChartMargin: 10px;
$maxWidth: 1200px;

.spinner-container {
  margin:$defaultChartMargin;
}

.correlation-charts-container-message {
  margin: $defaultChartMargin;
}

.correlation-instruction-text {
  text-align: center;
  color: blue;
  font-size: 1.6vw;
  font-style: italic;
}

.top-bar-container {
  display: flex;
}

.top-bar-element-right {
  width: 100%;
  text-align: right;
}

.wgcna-link-container {
  margin-top: 10px;
  margin-bottom: 5px;
}

.top-charts-container {
  padding : $tinyChartMargin 0 $tinyChartMargin $tinyChartMargin;
  display: flex;
}

.top-chart {
  width: calc((100vw / 3));
  aspect-ratio: $chartAspectRatioWide;
  margin: 0 $tinyChartMargin 0 0;
}

.correlation-charts-container {
  width: calc(100vw - ($correlationChartMargin * 2));
  aspect-ratio: $chartAspectRatio;
  margin: $defaultChartMargin $correlationChartMargin $defaultChartMargin $correlationChartMargin;
}

.bottom-charts-container {
  padding : $tinyChartMargin 0 $tinyChartMargin $tinyChartMargin;
  display: flex;
  flex-direction: row;
}

.bottom-chart {
  width: calc((100vw / 2));
  aspect-ratio: $chartAspectRatio;
  margin: 0 $tinyChartMargin 0 0;
}

@media screen and (max-width: $maxWidth) {
  .top-charts-container {
    flex-direction: column;
    margin: 0;
    padding : $tinyChartMargin $tinyChartMargin 0 $tinyChartMargin;
  }
  .bottom-charts-container {
    flex-direction: column;
    margin: 0;
    padding : $tinyChartMargin $tinyChartMargin 0 $tinyChartMargin;
  }

  .top-chart {
    width: calc(100vw - ($defaultChartMargin * 2));
    aspect-ratio: $chartAspectRatio;
    margin: 0 0 $defaultChartMargin 0;
  }

  .bottom-chart {
    width: calc(100vw - ($defaultChartMargin * 2));
    aspect-ratio: $chartAspectRatio;
    margin: 0 0 $defaultChartMargin 0;
  }

  .correlation-charts-container {
    width: calc(100vw - ($defaultChartMargin * 2));
    margin: $tinyChartMargin;
    aspect-ratio: $chartAspectRatio;
  }
}
