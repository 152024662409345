.search-container {
    margin: 20px;
    display: block;
}

.top-text {
    //color: blue;
    font-style: italic;
}

p {
    text-align: left;
    margin-bottom: 20px;
}

.search {
    margin-block-start: 1rem;
    display: flex;
    gap: 20px;
}

.search-button {
    margin: 20px;
}
