.info {
    margin: auto;
    font-size: 24px;
    color: black;
    margin-top: 90px;
}

.info-row {
    text-align: center;
  }
